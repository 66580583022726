<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="deng">
        <!-- <img width="100%" height='100%' src="../../assets/wai_daohang/background_img.png" alt=""> -->
        <div class="deng_wai">

            <img class="logo" @click="fanhui" src="../../assets/wai_daohang/logo_wai.png" alt="">
            <div class="deng_box">
                <ul class="deng_tit">
                    <li v-for="(i,index) in list" :class="path==i.path?'heise':''" @click="dianji_tiaozhuan(i)" :key="index" >{{i.name}}</li>
                </ul>
                <div class="deng_con">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { } from '../../api/api.js'
export default {
  name: 'deng',
  data () {
    return {
      list: [
        {
          name: '账户登录',
          path: '/login'
        },
        {
          name: '注册新账户',
          path: '/register'
        },
        {
          name: '忘记密码',
          path: '/forget'
        }
      ],
      path: ''
    }
  },
  mounted () {
  },
  created () {
    this.lujing(this.$route.path)
  },
  mounted () {
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      deep: true
    }
  },
  methods: {
    // 基础信息
    lujing (path) {
      this.path = path
    },
    fanhui () {
      this.$router.push('/')
    },
    dianji_tiaozhuan (i) {
      if (this.path != i.path) {
        this.$router.push(i.path)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './denglu.scss';
</style>
